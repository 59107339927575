import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { KcContext } from '../../kcContext';
import messages from './messages';
import styles from './LoginIdpLinkConfirm.module.scss';
import CongratulationsSvg from './Congratulations.svg';
import Button from 'KcApp/components/Button';
import { Typography, TextContainer, Link } from '@ryte/ui-components';

type KcLoginContext = Extract<
  KcContext,
  {
    pageId: 'login-idp-link-confirm.ftl';
  }
>;

const LoginIdpLinkConfirm = memo(
  ({ kcContext }: { kcContext: KcLoginContext }) => {
    const { message, url } = kcContext;
    const intl = useIntl();

    return (
      <div className={styles.congratulations}>
        <div className={styles.content}>
          <div className={styles.details}>
            <img className={styles.svg} src={CongratulationsSvg} />
            <div>
              <TextContainer align="center" className={styles.welcome}>
                <Typography variant="heading-1-extra">
                  {intl.formatMessage(messages.title)}
                </Typography>
                <Typography variant="heading-3">
                  {intl.formatMessage(messages.text)}
                </Typography>
              </TextContainer>
              <div className={styles.nextSteps}>
                <TextContainer align="center" className={styles.banner}>
                  <Typography variant="heading-3">
                    {intl.formatMessage(messages.next_steps)}
                  </Typography>
                  <Typography variant="text-small">
                    {message?.summary}
                  </Typography>
                </TextContainer>
              </div>
              <div className={styles.button}>
                <Link href={url.loginUrl}>
                  <Button text={intl.formatMessage(messages.ok_lets_go)} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default LoginIdpLinkConfirm;
