import GoogleLogo from './google_logo.svg';
import SemrushLogo from './semrush_logo.svg';

export const socialProvidersIcons: { [key: string]: any } = {
  google: GoogleLogo,
  oauth2: SemrushLogo,
};

export const FirstBrokerLoginEmailLocalStorageKey = 'firstBrokerLoginEmail';

export const SemrushIdentiyProviderName = 'oauth2';
