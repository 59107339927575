import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styles from './GenericMessagePage.module.scss';
import messages from './messages';
import { getEnvironmentConfig } from 'KcApp/configuration/utils';
import { Typography, TextContainer } from '@ryte/ui-components';
import Button from '../Button';

export type GenericMessagePageProps = {
  title: string;
  message: string | undefined;
  buttons?: Array<{
    text: string;
    variant: 'primary' | 'secondary';
    onClick: () => void;
  }>;
};

const GenericMessagePage = ({
  title,
  message,
  buttons = [],
}: GenericMessagePageProps) => {
  const config = getEnvironmentConfig();
  const intl = useIntl();

  const uiButtons = useMemo(() => {
    return [
      ...buttons,
      {
        text: intl.formatMessage(messages.back_to_ryte),
        variant: 'secondary' as const,
        onClick: () => {
          window.location.href = config.dashboardUrl;
          return;
        },
      },
    ];
  }, [buttons]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <TextContainer align="center" className={styles.textContainer}>
          <Typography variant="heading-1-extra">{title}</Typography>
          <Typography variant="text-regular">{message}</Typography>
        </TextContainer>
        {uiButtons.map(({ text, variant, onClick }, index) => {
          return (
            <div className={styles.buttonContainer} key={index}>
              <Button text={text} variant={variant} onClick={onClick} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GenericMessagePage;
