import React, { useEffect, useState } from 'react';
import FullPageSpinner from '../components/FullPageSpinner';
import { getEnvironmentConfig } from './utils';

export type Config = {
  dashboardUrl: string;
  registrationPageUrl: string;
  ryteHomepageUrl: string;
  freeBusinessTrialUrl: string;
  bookDemoUrl: string;
};

const ConfigContext = React.createContext<Config | null>(null);

export const ConfigProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [configState, setConfigState] = useState<Config | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const config = getEnvironmentConfig();
    setConfigState(config);
    setIsLoaded(true);
  }, []);
  return isLoaded ? (
    <ConfigContext.Provider value={configState}>
      {children}
    </ConfigContext.Provider>
  ) : (
    <FullPageSpinner />
  );
};
