import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useConstCallback } from 'powerhooks/useConstCallback';
import cn from 'classnames';
import { FormatXMLElementFn } from 'intl-messageformat';

import RegistrationOrLoginLink from '../../components/RegistrationOrLoginLink';
import Button from '../../components/Button';
import Input from '../../components/Input';
import styles from './Registration.module.scss';
import messages from './messages';
import Checkbox from '../../components/Checkbox';
import { SubpageProps, FormField, extractParamFromUrl } from './shared';
import { Typography, Link } from '@ryte/ui-components';

export const RegistrationForm: FC<SubpageProps> = props => {
  const {
    kcContext: { realm, url, auth, message, pageId, register },
    onStepChange,
  } = props;

  const intl = useIntl();

  const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState(
    true
  );

  const getInitialFormState = () => ({
    email: false,
    password: false,
    firstName: false,
    lastName: false,
  });

  const [touched, setTouched] = useState(() => getInitialFormState());

  const [formHasValue, setFormHasValue] = useState(() => getInitialFormState());

  const [formErrors, setFormErrors] = useState(() => getInitialFormState());

  const isError = message?.type === 'error';
  const summaryMessage = message?.summary;
  const hasKeycloakEmailError = summaryMessage?.toLowerCase().includes('mail');
  const hasKeycloakPasswordError = summaryMessage
    ?.toLowerCase()
    .includes('passw');

  const [email] = useState(() => extractParamFromUrl('email'));
  const [firstName] = useState(() => extractParamFromUrl('firstName'));
  const [lastName] = useState(() => extractParamFromUrl('lastName'));

  const [isInviteFlow] = useState(() => Boolean(extractParamFromUrl('email')));
  const emailEditDisabled =
    !hasKeycloakEmailError &&
    (isInviteFlow || Boolean(register.formData.email));

  const hasEmptyField = useMemo(() => {
    return !Object.values(formHasValue).every(Boolean);
  }, [formHasValue]);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  const handleBlur = useCallback(
    ({ target }: FocusEvent<HTMLInputElement>) => {
      const inputName = target.name as FormField;

      if (!touched[inputName]) {
        setTouched({
          ...touched,
          [inputName]: true,
        });
      }
    },
    [touched]
  );

  const handleInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const inputName = target.name as FormField;
      const value = target.value;

      if (!touched[inputName]) {
        setTouched({
          ...touched,
          [inputName]: true,
        });
      }

      const newValue = value.trim() !== '';
      if (formHasValue[inputName] !== newValue) {
        setFormHasValue({
          ...formHasValue,
          [inputName]: newValue,
        });
      }
    },
    [formHasValue]
  );

  const validatePrefilledData = useCallback(() => {
    const emailInput = emailRef.current;
    const passwordInput = passwordRef.current;
    const firstNameInput = firstNameRef.current;
    const lastNameInput = lastNameRef.current;

    setFormHasValue({
      email: Boolean(emailInput?.value),
      password: Boolean(passwordInput?.value),
      firstName: Boolean(firstNameInput?.value),
      lastName: Boolean(lastNameInput?.value),
    });
  }, [formHasValue]);

  useEffect(() => {
    const errors = Object.keys(touched).reduce((acc, item) => {
      const key = item as FormField;
      acc[key] = touched[key] && !formHasValue[key];
      return acc;
    }, {} as ReturnType<typeof getInitialFormState>);

    setFormErrors(errors);
  }, [touched, formHasValue]);

  useEffect(() => {
    setIsRegisterButtonDisabled(hasEmptyField);
  }, [hasEmptyField]);

  useEffect(() => {
    validatePrefilledData();

    if (isError) {
      return;
    }
  }, []);

  const onSubmit = useConstCallback(event => {
    setIsRegisterButtonDisabled(true);
    event.target['password-confirm'].value = event.target.password.value;
    event.target.username.value = event.target.email.value;
    return true;
  });

  const hasEmptyFirstName = formErrors.firstName && !formErrors.lastName;

  const hasEmptyCredentials = useMemo(() => {
    return Object.values(formErrors).find(Boolean) || false;
  }, [formErrors]);

  const hasSpecificKeycloakError =
    hasKeycloakEmailError || hasKeycloakPasswordError;

  const hasKeycloakError =
    isError && !hasEmptyCredentials && !hasSpecificKeycloakError;

  const hasEmailError = formErrors.email || hasKeycloakEmailError;
  const hasPasswordError = formErrors.password || hasKeycloakPasswordError;

  const subtitleText = useMemo(() => {
    return intl.formatMessage(
      isInviteFlow
        ? messages.invite_flow_subtitle
        : messages.normal_flow_subtitle
    );
  }, [isInviteFlow]);

  return (
    <div className={styles.registrationFormContainer}>
      <div className={styles.subTitle}>
        <Typography variant="text-regular">{subtitleText}</Typography>
      </div>
      <form
        className={styles.form}
        id="kc-form-register"
        onSubmit={onSubmit}
        action={url.registrationAction}
        method="POST">
        <Input
          onChange={handleInputChange}
          onBlur={handleBlur}
          placeholder={intl.formatMessage(messages.email)}
          tabIndex={1}
          icon={'mail'}
          id="email"
          style="top"
          name="email"
          defaultValue={email || (register.formData.email ?? '')}
          type="email"
          {...(emailEditDisabled
            ? { readOnly: true }
            : { autoComplete: 'off' })}
          {...((hasEmailError || hasKeycloakError) && {
            autoFocus: true,
          })}
          error={hasEmailError || hasKeycloakError}
          className={cn({
            [styles.bottomInputError]: hasPasswordError || hasKeycloakError,
          })}
          reference={emailRef}
        />
        <Input
          onChange={handleInputChange}
          onBlur={handleBlur}
          tabIndex={2}
          placeholder={intl.formatMessage(messages.password)}
          id="password"
          icon={'lock'}
          style="bottom"
          name="password"
          type="password"
          autoComplete="off"
          {...(hasPasswordError && {
            autoFocus: true,
          })}
          error={hasPasswordError || hasKeycloakError}
          className={cn({
            [styles.topInputError]: hasEmailError && !hasPasswordError,
          })}
          reference={passwordRef}
        />
        <div className={styles.bottomInputBlock}>
          <Input
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder={intl.formatMessage(messages.firstName)}
            tabIndex={3}
            icon={'user'}
            id="firstName"
            style="top"
            name="firstName"
            defaultValue={firstName || (register.formData.firstName ?? '')}
            type="text"
            autoComplete={'off'}
            error={formErrors.firstName || hasKeycloakError}
            className={cn({
              [styles.bottomInputError]:
                formErrors.lastName || hasKeycloakError,
            })}
            reference={firstNameRef}
          />
          <Input
            onChange={handleInputChange}
            onBlur={handleBlur}
            placeholder={intl.formatMessage(messages.lastName)}
            tabIndex={4}
            icon={'user'}
            id="lastName"
            style="bottom"
            name="lastName"
            defaultValue={lastName || (register.formData.lastName ?? '')}
            type="text"
            autoComplete={'off'}
            error={formErrors.lastName || hasKeycloakError}
            className={cn({
              [styles.topInputError]: hasEmptyFirstName,
            })}
            reference={lastNameRef}
          />
        </div>
        <div>
          {(hasEmptyCredentials ||
            hasSpecificKeycloakError ||
            hasKeycloakError) && (
            <div className={styles.errorMessage}>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    summaryMessage && !hasEmptyCredentials
                      ? summaryMessage
                      : intl.formatMessage(messages.error_message),
                }}
              />
            </div>
          )}
        </div>
        {isInviteFlow && (
          <Checkbox
            id="kc-form-checkbox"
            name="user.attributes.emailConsent"
            label={intl.formatMessage(messages.news_and_offers)}
          />
        )}
        <div id="kc-form-buttons" className={styles.buttons}>
          <input
            type="password"
            id="password-confirm"
            name="password-confirm"
            hidden
          />
          <input type="text" id="username" name="username" hidden />
          <Button
            text={intl.formatMessage(messages.sign_up)}
            disabled={isRegisterButtonDisabled}
          />
        </div>
      </form>
      <RegistrationOrLoginLink status={'login'} />
      {isInviteFlow && (
        <div className={styles.inviteSubTitleDescriptionContainer}>
          <Typography variant="text-small-light">
            {intl.formatMessage(messages.invite_flow_sign_up_description, {
              terms: msg => (
                <Link href={intl.formatMessage(messages.termsLink)}>{msg}</Link>
              ),
              pp: msg => (
                <Link href={intl.formatMessage(messages.privacyPolicyLink)}>
                  {msg}
                </Link>
              ),
            } as Record<string, FormatXMLElementFn<string, any>>)}
          </Typography>
        </div>
      )}
    </div>
  );
};

/* eslint-enable */
