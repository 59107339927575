import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'keycloakify.reset_password.email',
    defaultMessage: 'Email Address',
  },
  reset_password: {
    id: 'keycloakify.reset_password.reset_password',
    defaultMessage: 'Reset Password',
  },
  title: {
    id: 'keycloakify.reset_password.title',
    defaultMessage: 'Reset your password',
  },
  dont_have_account: {
    id: 'keycloakify.reset_password.dont_have_account',
    defaultMessage: "Don't have an account?",
  },
  get_free_account: {
    id: 'keycloakify.reset_password.get_free_account',
    defaultMessage: 'Get your free account now',
  },
  back_to_login: {
    id: 'keycloakify.reset_password.back_to_login',
    defaultMessage: 'Back to Login',
  },
  get_free_account_url: {
    id: 'keycloakify.reset_password.get_free_account_url',
    defaultMessage: 'https://en.ryte.com/leads/free-seo-analysis/',
  },
});
