import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'keycloakify.info.title',
    defaultMessage: 'Ok, that worked!',
  },
  button_text: {
    id: 'keycloakify.info.button_text',
    defaultMessage: 'Confirm',
  },
});
