import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import styles from './Footer.module.scss';
import { Link } from '@ryte/ui-components';

const Footer = () => {
  const intl = useIntl();
  const year = new Date().getFullYear();
  return (
    <div className={styles.footer} data-testid="Footer">
      <Link href="https://en.ryte.com/about/tou/">
        {intl.formatMessage(messages.terms_of_use)}
      </Link>{' '}
      |{' '}
      <Link href="https://en.ryte.com/about/privacy/">
        {intl.formatMessage(messages.privacy_policy)}
      </Link>{' '}
      |{' '}
      <Link href="https://en.ryte.com/about/tos/">
        {intl.formatMessage(messages.terms_and_conditions)}
      </Link>{' '}
      |{' '}
      <Link href="https://en.ryte.com/about/imprint/">
        {intl.formatMessage(messages.imprint)}
      </Link>{' '}
      <br /> {intl.formatMessage(messages.copyright, { year })}
    </div>
  );
};

export default Footer;
