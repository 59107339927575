import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { KcContext } from '../../kcContext';
import GenericMessagePage from '../../components/GenericMessagePage';
import messages from './messages';

type KcLoginContext = Extract<KcContext, { pageId: 'error.ftl' }>;

const Error = memo(({ kcContext }: { kcContext: KcLoginContext }) => {
  const { message } = kcContext;
  const intl = useIntl();

  return (
    <GenericMessagePage
      title={intl.formatMessage(messages.title)}
      message={message.summary}
    />
  );
});

export default Error;
