import React, { useState, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useConstCallback } from 'powerhooks/useConstCallback';

import { KcContext } from '../../kcContext';
import Button from '../../components/Button';
import Input from '../../components/Input';
import styles from './UpdateEmail.module.scss';
import messages from './messages';
import { Typography } from '@ryte/ui-components';

type KcUpdateEmailContext = Extract<KcContext, { pageId: 'update-email.ftl' }>;

const LoginUpdateEmail = memo(
  ({ kcContext }: { kcContext: KcUpdateEmailContext }) => {
    const { url, message } = kcContext;
    const isError = message && message.type === 'error';

    const intl = useIntl();

    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const [didUserTypeEmail, setDidUserTypeEmail] = useState(false);

    const emailInputsHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
      setDidUserTypeEmail(event.target.value.trim() !== '');

    useEffect(() => {
      didUserTypeEmail
        ? setIsSubmitButtonDisabled(false)
        : setIsSubmitButtonDisabled(true);
    }, [didUserTypeEmail]);

    useEffect(() => {
      if (isError) {
        console.error(message);
      }
    }, [isError]);

    const onSubmit = useConstCallback(() => {
      setIsSubmitButtonDisabled(true);
      return true;
    });

    return (
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          <Typography variant="heading-1-extra">
            {intl.formatMessage(messages.title)}
          </Typography>
          <div id="kc-form" className={styles.emailForm}>
            <div id="kc-form-wrapper" className={styles.emailFormWrapper}>
              <form
                id="kc-update-email-form"
                onSubmit={onSubmit}
                action={url.loginAction}
                method="post">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  hidden
                  checked
                />
                <Input
                  className={styles.bottomMargin}
                  icon={'mail'}
                  onChange={emailInputsHandler}
                  placeholder={intl.formatMessage(messages.email)}
                  tabIndex={1}
                  id="email"
                  name="email"
                  type="email"
                  autoFocus
                />
                <Button
                  text={intl.formatMessage(messages.update_email)}
                  disabled={isSubmitButtonDisabled}
                />
                {isError && (
                  <Typography variant="text-regular" textColor="negative">
                    {message?.summary}
                  </Typography>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default LoginUpdateEmail;
