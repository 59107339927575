import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'features.intro_survey.congratulations.title',
    defaultMessage: 'Congratulations!',
  },
  text: {
    id: 'features.intro_survey.congratulations.text',
    defaultMessage: 'Your Ryte account is created.',
  },
  next_steps: {
    id: 'features.intro_survey.congratulations.next_steps',
    defaultMessage: "Here's what happens next",
  },
  ok_lets_go: {
    id: 'features.intro_survey.congratulations.ok_lets_go',
    defaultMessage: "Ok, let's go",
  },
});
