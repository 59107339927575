import { defineMessages } from 'react-intl';

export default defineMessages({
  sign_in_with: {
    id: 'keycloakify.login.sign_in_with',
    defaultMessage: 'Sign in with',
  },
  sign_up_with: {
    id: 'keyclokify.register.sign_up_with',
    defaultMessage: 'Sign up with',
  },
});
