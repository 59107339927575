import { defineMessages } from 'react-intl';

export default defineMessages({
  company: {
    id: 'keycloakify.update_profile.company',
    defaultMessage: 'Company Name',
  },
  domain: {
    id: 'keycloakify.update_profile.domain',
    defaultMessage: 'Website Domain',
  },
  domain_explanation: {
    id: 'keycloakify.update_profile.domain_explanation',
    defaultMessage:
      'With the domain of your website you want to analyze, we create a Ryte project in the background. Once you are on the project dashboard you can start your website analysis. ',
  },
  create_account: {
    id: 'keycloakify.update_profile.create_account',
    defaultMessage: 'Create Account',
  },
  title: {
    id: 'keycloakify.update_profile.title',
    defaultMessage: 'Create your Ryte Account',
  },
  email: {
    id: 'keycloakify.update_profile.email',
    defaultMessage: 'Email',
  },
  first_name: {
    id: 'keycloakify.update_profile.first_name',
    defaultMessage: 'First Name',
  },
  last_name: {
    id: 'keycloakify.update_profile.last_name',
    defaultMessage: 'Last Name',
  },
  log_in: {
    id: 'keycloakify.update_profile.complete_signup',
    defaultMessage: 'Complete sign up',
  },
  social_provider_title: {
    id: 'keycloakify.update_profile.social_provider_title',
    defaultMessage: 'Please verify your Data',
  },
  news_and_offers: {
    id: 'keycloakify.update_profile.news_and_offers',
    defaultMessage:
      'Yes, I would like to be informed about product news and other offers from Ryte by email.',
  },
  social_provider_description: {
    id: 'keycloakify.update_profile.social_provider_description',
    defaultMessage:
      'Please check if the Data provided by your Google Account is correct.',
  },
  error_message: {
    id: 'keycloakify.update_profile.error_message',
    defaultMessage: 'Please enter your Name',
  },
});
