import { defineMessages } from 'react-intl';

export default defineMessages({
  dont_have_account: {
    id: 'keycloakify.login.dont_have_account',
    defaultMessage: "Don't have an account?",
  },
  already_have_account: {
    id: 'keycloakify.login.already_have_account',
    defaultMessage: 'Already have an account?',
  },
  log_in: {
    id: 'keycloakify.login.log_in',
    defaultMessage: 'Go to login!',
  },
  get_free_business_trial: {
    id: 'keycloakify.login.get_free_business_trial',
    defaultMessage: 'Get a free trial!',
  },
  book_demo: {
    id: 'keycloakify.login.book_demo',
    defaultMessage: 'Book a free demo!',
  },
});
