import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'keycloakify.update_email.email',
    defaultMessage: 'Email Address',
  },
  update_email: {
    id: 'keycloakify.update_email.update_email',
    defaultMessage: 'Update Email',
  },
  title: {
    id: 'keycloakify.update_email.title',
    defaultMessage: 'Update your email address',
  },
});
