import React, { useState, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useConstCallback } from 'powerhooks/useConstCallback';

import { KcContext } from '../../kcContext';
import RegistrationOrLoginLink from '../../components/RegistrationOrLoginLink';
import Button from '../../components/Button';
import Input from '../../components/Input';
import styles from './LoginResetPassword.module.scss';
import messages from './messages';
import { Typography, Link } from '@ryte/ui-components';

type KcLoginResetPasswordContext = Extract<
  KcContext,
  { pageId: 'login-reset-password.ftl' }
>;

const LoginResetPassword = memo(
  ({ kcContext }: { kcContext: KcLoginResetPasswordContext }) => {
    const { url, message } = kcContext;
    const isError = message && message.type === 'error';

    const intl = useIntl();

    const [
      isResetPasswordButtonDisabled,
      setIsResetPasswordButtonDisabled,
    ] = useState(true);
    const [didUserTypeEmail, setDidUserTypeEmail] = useState(false);

    const emailInputsHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
      setDidUserTypeEmail(event.target.value.trim() !== '');

    useEffect(() => {
      didUserTypeEmail
        ? setIsResetPasswordButtonDisabled(false)
        : setIsResetPasswordButtonDisabled(true);
    }, [didUserTypeEmail]);

    const onSubmit = useConstCallback(() => {
      setIsResetPasswordButtonDisabled(true);
      return true;
    });

    return (
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          <Typography variant="heading-1-extra">
            {intl.formatMessage(messages.title)}
          </Typography>
          <div id="kc-form" className={styles.loginForm}>
            <div id="kc-form-wrapper" className={styles.loginFormWrapper}>
              <form
                id="kc-reset-password-form"
                onSubmit={onSubmit}
                action={url.loginAction}
                method="post">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  hidden
                  checked
                />
                <Input
                  className={styles.bottomMargin}
                  icon={'mail'}
                  onChange={emailInputsHandler}
                  placeholder={intl.formatMessage(messages.email)}
                  tabIndex={1}
                  id="username"
                  name="username"
                  type="email"
                  autoFocus
                />
                <Button
                  text={intl.formatMessage(messages.reset_password)}
                  disabled={isResetPasswordButtonDisabled}
                />
              </form>
              <Link href={url.loginUrl}>
                {intl.formatMessage(messages.back_to_login)}
              </Link>
            </div>
            <RegistrationOrLoginLink status={'login'} />
          </div>
        </div>
      </div>
    );
  }
);

export default LoginResetPassword;
