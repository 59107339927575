import React from 'react';
import './index.scss';
import { kcContext } from './KcApp/kcContext';
import { KcApp } from './KcApp';
import Wrapper from './KcApp/components/Wrapper';
import { ThemeProvider } from '@ryte/ui-components';

// a bit hacky, but we need this part to mock the context in cypress tests
const win = window as any;
const context = win.Cypress ? win.external : kcContext;

export const App = () => (
  <ThemeProvider theme="2022" compatMode>
    <Wrapper>
      <KcApp kcContext={context} />
    </Wrapper>
  </ThemeProvider>
);
