export const configDevelopment = {
  dashboardUrl: 'http://localhost:8096/',
  registrationPageUrl: 'https://en.ryte-test.com/leads/free-seo-analysis/',
  ryteHomepageUrl: 'https://en.ryte-test.com/',
  freeBusinessTrialUrl: 'https://en.ryte-test.com/register/free-trial/',
  bookDemoUrl: 'https://en.ryte-test.com/leads/demo/',
};
export const configTesting = {
  dashboardUrl: 'https://platform.ryte-test.com/',
  registrationPageUrl: 'https://en.ryte-test.com/leads/free-seo-analysis/',
  ryteHomepageUrl: 'https://en.ryte-test.com/',
  freeBusinessTrialUrl: 'https://en.ryte-test.com/register/free-trial/',
  bookDemoUrl: 'https://en.ryte-test.com/leads/demo/',
};
export const configProduction = {
  dashboardUrl: 'https://platform.ryte.com/',
  registrationPageUrl: 'https://ryte.com/leads/free-seo-analysis/',
  ryteHomepageUrl: 'https://ryte.com/',
  freeBusinessTrialUrl: 'https://en.ryte.com/register/free-trial/',
  bookDemoUrl: 'https://en.ryte.com/leads/demo/',
};
