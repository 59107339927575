import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import { KcContext } from '../../kcContext';
import messages from './messages';
import styles from './LoginVerifyEmail.module.scss';
import Letter from './Letter.svg';
import { Typography, TextContainer } from '@ryte/ui-components';

type KcLoginContext = Extract<KcContext, { pageId: 'login-verify-email.ftl' }>;

const LoginVerifyEmail = memo(
  ({ kcContext }: { kcContext: KcLoginContext }) => {
    const { message } = kcContext;
    const intl = useIntl();

    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <img className={styles.svg} src={Letter} />
          <TextContainer align="center">
            <Typography variant="heading-1-extra">
              {intl.formatMessage(messages.title)}
            </Typography>
            <Typography variant="text-regular">{message?.summary}</Typography>
          </TextContainer>
        </div>
      </div>
    );
  }
);

export default LoginVerifyEmail;
