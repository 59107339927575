import React, { useState } from 'react';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  id: string;
  label: string;
  name?: string;
  value?: string | number;
  checked?: boolean;
  disabled?: boolean;
  style?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
  id,
  label,
  name,
  value,
  checked = false,
  disabled = false,
  style,
  onChange,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    if (onChange) onChange(event);
  };

  const checkedValue = isChecked ? 'true' : undefined;

  return (
    <div className={cn(styles.checkbox, style || '')}>
      <input
        data-testid="CheckboxInput"
        type="checkbox"
        id={id}
        name={name || id}
        value={value || checkedValue}
        disabled={disabled}
        checked={isChecked}
        onChange={onChangeHandler}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
