import React from 'react';

import styles from './InformativeLink.module.scss';
import { Link, Icon } from '@ryte/ui-components';

export interface InformativeLinkProps {
  question: string;
  linkLabel: string;
  link: string;
}

const InformativeLink = ({
  question,
  linkLabel,
  link,
}: InformativeLinkProps) => {
  return (
    <div className={styles.informativeLinkContainer}>
      {question}
      <Link href={link}>
        {linkLabel}
        <Icon icon={'arrow_right'} size={16} className={styles.arrow} />
      </Link>
    </div>
  );
};

export default InformativeLink;
