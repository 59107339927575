import { defineMessages } from 'react-intl';

export default defineMessages({
  privacy_policy: {
    id: 'keycloakify.footer.privacy_policy',
    defaultMessage: 'Privacy Policy',
  },
  terms_of_use: {
    id: 'keycloakify.footer.terms_of_use',
    defaultMessage: 'Terms of use',
  },
  terms_and_conditions: {
    id: 'keycloakify.footer.terms_and_conditions',
    defaultMessage: 'Terms and conditions',
  },
  imprint: {
    id: 'keycloakify.footer.imprint',
    defaultMessage: 'Imprint',
  },
  copyright: {
    id: 'keycloakify.footer.copyright',
    defaultMessage: 'Copyright Ⓒ {year} Ryte',
  },
});
