import React from 'react';
import { useIntl } from 'react-intl';

import InformativeLink from './components/InformativeLink';
import messages from './messages';
import { getEnvironmentConfig } from '../../configuration/utils';

const RegistrationOrLoginLink = ({ status }: any) => {
  const intl = useIntl();
  const config = getEnvironmentConfig();

  const bookDemoUrl =
    intl.locale === 'de'
      ? config.bookDemoUrl.replace('//en.', '//de.')
      : config.bookDemoUrl;

  return (
    <>
      {status === 'registration' ? (
        <InformativeLink
          question={intl.formatMessage(messages.dont_have_account)}
          linkLabel={intl.formatMessage(messages.book_demo)}
          link={bookDemoUrl}
        />
      ) : (
        <InformativeLink
          question={intl.formatMessage(messages.already_have_account)}
          linkLabel={intl.formatMessage(messages.log_in)}
          link={config.dashboardUrl}
        />
      )}
    </>
  );
};

export default RegistrationOrLoginLink;
