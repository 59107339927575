import React, { useState, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useConstCallback } from 'powerhooks/useConstCallback';

import { KcContext } from '../../kcContext';
import Button from '../../components/Button';
import Input from '../../components/Input';
import styles from './LoginUpdatePassword.module.scss';
import messages from './messages';
import { Typography } from '@ryte/ui-components';

type KcLoginContext = Extract<
  KcContext,
  { pageId: 'login-update-password.ftl' }
>;

const LoginUpdatePassword = memo(
  ({ kcContext }: { kcContext: KcLoginContext }) => {
    const { url, message } = kcContext;

    const intl = useIntl();

    const [
      isSavePasswordButtonDisabled,
      setIsSavePasswordButtonDisabled,
    ] = useState(true);
    const [didUserEnterPassword, setDidUserEnterPassword] = useState(false);
    const [didUserConfirmPassword, setDidUserConfirmPassword] = useState(false);
    const isError = message && message.type === 'error';

    const confirmPasswordHandler = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => setDidUserConfirmPassword(event.target.value.trim() !== '');
    const enterPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
      setDidUserEnterPassword(event.target.value.trim() !== '');

    useEffect(() => {
      didUserConfirmPassword && didUserEnterPassword
        ? setIsSavePasswordButtonDisabled(false)
        : setIsSavePasswordButtonDisabled(true);
    }, [didUserConfirmPassword, didUserEnterPassword]);

    const onSubmit = useConstCallback(() => {
      setIsSavePasswordButtonDisabled(true);
      return true;
    });

    return (
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          <Typography variant={'heading-1-extra'}>
            {intl.formatMessage(messages.title)}
          </Typography>
          <div id="kc-form" className={styles.loginForm}>
            <div id="kc-form-wrapper">
              <form
                id="kc-passwd-update-form"
                onSubmit={onSubmit}
                action={url.loginAction}
                method="post">
                <Input
                  onChange={enterPasswordHandler}
                  placeholder={intl.formatMessage(messages.password)}
                  tabIndex={1}
                  icon={'lock'}
                  id="password-new"
                  name="password-new"
                  type="password"
                  autoFocus
                />
                <Input
                  className={styles.marginTop}
                  onChange={confirmPasswordHandler}
                  tabIndex={2}
                  placeholder={intl.formatMessage(messages.confirm_password)}
                  icon={'lock'}
                  id="password-confirm"
                  name="password-confirm"
                  type="password"
                  error={isError}
                />
                <div>
                  {message && isError && (
                    <div className={styles.errorMessage}>
                      <Typography
                        variant={'text-regular'}
                        textColor={'negative'}>
                        {message.summary}
                      </Typography>
                    </div>
                  )}
                </div>
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  hidden
                  checked
                />
                <div id="kc-form-buttons" className={styles.buttons}>
                  <Button
                    text={intl.formatMessage(messages.save_password)}
                    disabled={isSavePasswordButtonDisabled}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default LoginUpdatePassword;
