import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './Registration.module.scss';
import messages from './messages';
import { RegistrationForm } from './RegistrationForm';
import { RegistrationTypeSelector } from './RegistrationSelectType';
import { extractParamFromUrl, PageProps, RegistrationStep } from './shared';
import { Typography } from '@ryte/ui-components';

const Registration = memo(({ kcContext }: PageProps) => {
  const { realm, url, auth, message, pageId, register, social } = kcContext;

  const intl = useIntl();

  const [registrationStep, setRegistrationStep] = useState<
    undefined | RegistrationStep
  >(undefined);

  const isError = message?.type === 'error';

  const [isInviteFlow] = useState(() => Boolean(extractParamFromUrl('email')));

  const [hasEmailScope] = useState(
    () => extractParamFromUrl('scope') === 'email'
  );

  useEffect(() => {
    if (hasEmailScope) {
      setRegistrationStep(RegistrationStep.Form);
    }
  }, []);

  useEffect(() => {
    if (isError || isInviteFlow || hasEmailScope) {
      setRegistrationStep(RegistrationStep.Form);
      return;
    }

    setRegistrationStep(RegistrationStep.SelectType);
  }, [isError, isInviteFlow, hasEmailScope]);

  if (registrationStep === undefined) {
    return null;
  }

  const doesSocialProviderExist = social?.providers !== undefined;

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.formWrapper, {
          [styles.withSocialProvider]:
            doesSocialProviderExist &&
            registrationStep === RegistrationStep.SelectType,
          [styles.selectTypePage]:
            registrationStep === RegistrationStep.SelectType,
        })}>
        <Typography variant="heading-1-extra">
          {intl.formatMessage(messages.title)}
        </Typography>
        {registrationStep === RegistrationStep.SelectType && (
          <RegistrationTypeSelector
            kcContext={kcContext}
            onStepChange={setRegistrationStep}
          />
        )}
        {registrationStep === RegistrationStep.Form && (
          <RegistrationForm
            kcContext={kcContext}
            onStepChange={setRegistrationStep}
          />
        )}
      </div>
    </div>
  );
});

/* eslint-enable */

export default Registration;
