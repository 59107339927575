/* eslint-disable */
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FormatXMLElementFn } from 'intl-messageformat';

import RegistrationOrLoginLink from '../../components/RegistrationOrLoginLink';
import Button from '../../components/Button';
import styles from './Registration.module.scss';
import messages from './messages';
import SocialButton from '../../components/SocialLoginButton';
import { RegistrationStep, SubpageProps } from './shared';
import { Typography, Link } from '@ryte/ui-components';

export const RegistrationTypeSelector: FC<SubpageProps> = props => {
  const {
    kcContext: { realm, url, auth, message, pageId, register, social },
    onStepChange,
  } = props;

  const intl = useIntl();

  const socialRegistrationHandler = (url: string) => {
    window.location.replace(url);
  };

  const moveToEmailRegistration = useCallback(() => {
    onStepChange(RegistrationStep.Form);
  }, []);

  const doesSocialProviderExist = social?.providers !== undefined;

  return (
    <div id="kc-form" className={styles.form}>
      <div className={styles.subTitle}>
        <Typography variant="text-regular">
          {intl.formatMessage(messages.select_type_subtitle)}
        </Typography>
      </div>
      <div id="kc-form-wrapper">
        {doesSocialProviderExist && (
          <>
            <div id="kc-social-providers">
              {doesSocialProviderExist &&
                social.providers?.map(provider => (
                  <SocialButton
                    key={provider.providerId}
                    socialProvider={provider}
                    type="register"
                    onClick={() => socialRegistrationHandler(provider.loginUrl)}
                  />
                ))}
            </div>
            <div className={styles.socialProviderWrapper}>
              <div className={styles.socialProviderSeparator}>
                <hr />
                <span>{intl.formatMessage(messages.or)}</span>
                <hr />
              </div>
            </div>
          </>
        )}
        <div className={styles.buttonsContainer}>
          <Button
            text={intl.formatMessage(messages.sign_up_with_email)}
            onClick={moveToEmailRegistration}
          />
          <Typography variant="text-small-light">
            {intl.formatMessage(messages.select_type_sign_up_description, {
              terms: msg => (
                <Link href={intl.formatMessage(messages.termsLink)}>{msg}</Link>
              ),
              pp: msg => (
                <Link href={intl.formatMessage(messages.privacyPolicyLink)}>
                  {msg}
                </Link>
              ),
            } as Record<string, FormatXMLElementFn<string, any>>)}
          </Typography>
        </div>
        <RegistrationOrLoginLink status={'login'} />
      </div>
    </div>
  );
};
