import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'keycloakify.register.email',
    defaultMessage: 'Email Address',
  },
  password: {
    id: 'keycloakify.register.password',
    defaultMessage: 'Password',
  },
  firstName: {
    id: 'keycloakify.register.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'keycloakify.register.lastName',
    defaultMessage: 'Last Name',
  },
  sign_up: {
    id: 'keycloakify.register.sign_up',
    defaultMessage: 'Sign up',
  },
  sign_up_with_email: {
    id: 'keycloakify.register.sign_up_email',
    defaultMessage: 'Sign up with email',
  },
  select_type_sign_up_description: {
    id: 'keycloakify.register.selectType.description',
    defaultMessage:
      'By clicking “Sign up with Google” or “Sign up with email” above, you acknowledge that you have read and understood, and agree to Rytes <terms>Terms & Conditions</terms> and <pp>Privacy Policy</pp>.',
  },
  title: {
    id: 'keycloakify.register.title',
    defaultMessage: 'Create your Account',
  },
  select_type_subtitle: {
    id: 'keycloakify.register.selectType.subtitle',
    defaultMessage: 'Choose how to sign up!',
  },
  normal_flow_subtitle: {
    id: 'keycloakify.register.normalFlow.subtitle',
    defaultMessage: 'Please provide us with your registration details.',
  },
  invite_flow_subtitle: {
    id: 'keycloakify.register.inviteFlow.subtitle',
    defaultMessage:
      'You have been invited to a project in Ryte. Please register!',
  },
  or: {
    id: 'keycloakify.register.or',
    defaultMessage: 'or',
  },
  error_message: {
    id: 'keycloakify.register.error_message',
    defaultMessage: 'Please enter your credentials',
  },
  termsLink: {
    id: 'keycloakify.register.selectType.termsLink',
    defaultMessage: 'https://en.ryte.com/about/tou',
  },
  privacyPolicyLink: {
    id: 'keycloakify.register.selectType.privacyLink',
    defaultMessage: 'https://en.ryte.com/about/privacy',
  },
  news_and_offers: {
    id: 'keycloakify.register.news_and_offers',
    defaultMessage:
      'Yes, I would like to be informed about product news and other offers from Ryte by email.',
  },
  invite_flow_sign_up_description: {
    id: 'keycloakify.register.inviteFlow.sign_up_description',
    defaultMessage:
      'By clicking “Create Account” above, you acknowledge that you have read and understood, and agree to Rytes <terms>Terms & Conditions</terms> and <pp>Privacy Policy</pp>.',
  },
});
