/* eslint-disable */

import { KcContext } from '../../kcContext';

export type KcRegistrationContext = Extract<
  KcContext,
  { pageId: 'register.ftl' }
>;
export type PageProps = { kcContext: KcRegistrationContext };

export type SubpageProps = PageProps & {
  onStepChange: (step: RegistrationStep) => void;
};

export type FormField = 'email' | 'password' | 'firstName' | 'lastName';

export enum RegistrationStep {
  SelectType,
  Form,
}

export const extractParamFromUrl = (param: string) => {
  const url = new URL(window.location.href);
  return (url.searchParams.get(param) || '').trim();
};

/* eslint-enable */
