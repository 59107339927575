import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { KcContext } from '../../kcContext';
import GenericMessagePage from '../../components/GenericMessagePage';
import messages from './messages';

type KcLoginContext = Extract<KcContext, { pageId: 'info.ftl' }>;

const Info = memo(({ kcContext }: { kcContext: KcLoginContext }) => {
  const { message, actionUri } = kcContext;

  const intl = useIntl();

  const buttons = useMemo(() => {
    const list = [];
    if (actionUri && actionUri !== '#') {
      list.push({
        text: intl.formatMessage(messages.button_text),
        onClick: () => {
          window.location.href = actionUri;
        },
        variant: 'primary' as const,
      });
    }
    return list;
  }, [actionUri]);

  return (
    <GenericMessagePage
      title={intl.formatMessage(messages.title)}
      message={message?.summary}
      buttons={buttons}
    />
  );
});

export default Info;
