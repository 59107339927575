import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './FullPageSpinner.module.scss';
import messages from './messages';
import { Spinner } from '@ryte/ui-components';

const FullPageSpinner = () => (
  <div className={styles.spinnerContainer}>
    <Spinner size={'large'} />
    <span>
      <FormattedMessage {...messages.loading} />
    </span>
  </div>
);

export default FullPageSpinner;
