import { getKcContext } from 'keycloakify/login';

export const { kcContext } = getKcContext<
  | {
      pageId: 'register.ftl';
      /**
       * Defined when you use the keycloak-mail-whitelisting keycloak plugin
       * (https://github.com/micedre/keycloak-mail-whitelisting)
       */
      authorizedMailDomains: string[];
    }
  // NOTE: A 'keycloakify' field must be added
  // in the package.json to generate these pages
  | {
      pageId: 'login-update-password.ftl';
    }
>({
  /**
   * IMPORTANT: mockPageId should be used only when developing locally
   * 💣 💣 💣 IT MUST NOT BE PUSHED TO PRODUCTION BECAUSE THAT BREAKS EVERYTHING 💣 💣 💣
   *
   */
  // mockPageId: 'info.ftl',
  // mockPageId: 'error.ftl',
  // mockPageId: 'login.ftl',
  // mockPageId: 'login-update-password.ftl',
  // mockPageId: 'login-reset-password.ftl',
  // mockPageId: 'login-update-profile.ftl',
  // mockPageId: 'login-idp-link-confirm.ftl',
  // mockPageId: 'login-verify-email.ftl',
  // mockPageId: 'register.ftl',
  // mockPageId: 'update-email.ftl',
  /**
   * Customize the simulated kcContext that will let us
   * dev the page outside keycloak (with auto-reload)
   */
  mockData: [
    {
      pageId: 'login.ftl',
      // This means we'll test this page using English as current language
      locale: {
        currentLanguageTag: 'en',
      },
      // message: { type: 'info', summary: 'This is a test message' },
      message: {
        summary: 'Authenticate to link your account with oauth2',
        type: 'info',
      },
    },
    {
      pageId: 'register.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
      authorizedMailDomains: [
        'example.com',
        'another-example.com',
        '*.yet-another-example.com',
        '*.example.com',
        'hello-world.com',
      ],
    },
    {
      pageId: 'login-update-password.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
    },
    {
      pageId: 'update-email.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
    },
    {
      pageId: 'register.ftl',
      locale: {
        currentLanguageTag: 'en',
      },
      authorizedMailDomains: [
        'example.com',
        'another-example.com',
        '*.yet-another-example.com',
        '*.example.com',
        'hello-world.com',
      ],
    },
  ],
});

export type KcContext = NonNullable<typeof kcContext>;
