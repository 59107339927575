import React from 'react';
import { useIntl } from 'react-intl';

import styles from './SocialLoginButton.module.scss';
import { socialProvidersIcons } from '../../../constants';
import messages from './messages';
import { Button, Typography } from '@ryte/ui-components';

export type SocialProviderType = {
  alias: string;
  displayName: string;
  providerId: string;
};

export type SocialLoginButtonProps = {
  onClick?: React.MouseEventHandler;
  socialProvider: SocialProviderType;
  type?: 'register' | 'login';
};

const SocialButton = ({
  onClick,
  socialProvider,
  type = 'login',
}: SocialLoginButtonProps) => {
  const intl = useIntl();
  const displayName =
    socialProvider.alias === 'oauth2' ? 'Semrush' : socialProvider.displayName;
  const style =
    socialProvider.alias === 'oauth2'
      ? { width: '55px', marginRight: '-15px' }
      : {};
  return (
    <Button
      variant={'neutral-inverted'}
      onClick={onClick}
      key={socialProvider.providerId}
      className={styles.socialProviderButton}
      text={
        <div className={styles.textContainer}>
          <img
            className={styles.icon}
            src={socialProvidersIcons[socialProvider.displayName.toLowerCase()]}
            style={style}
          />
          <span>
            {intl.formatMessage(
              type === 'login' ? messages.sign_in_with : messages.sign_up_with
            )}
            {` `}
            {displayName}
          </span>
        </div>
      }
    />
  );
};

export default SocialButton;
