import React, { lazy } from 'react';
import { memo } from 'react';
import Fallback from 'keycloakify/login';
import { I18n } from 'keycloakify/login/i18n/i18n';

import { KcContext } from './kcContext';
import Login from './keycloak-theme/login';
import LoginResetPassword from './keycloak-theme/login-reset-password';
import LoginUpdatePassword from './keycloak-theme/login-update-password';
import LoginUpdateProfile from './keycloak-theme/login-update-profile';
import Error from './keycloak-theme/error';
import Info from './keycloak-theme/info';
import Registration from './keycloak-theme/registration';
import LoginVerifyEmail from './keycloak-theme/login-verify-email';
import LoginIdpLinkConfirm from './keycloak-theme/login-idp-link-confirm';
import UpdateEmail from './keycloak-theme/update-email';

const DefaultTemplate = lazy(() => import('keycloakify/login/Template'));

type ApplicationProps = { kcContext: KcContext };

export const KcApp = memo(({ kcContext }: ApplicationProps) => {
  if (!kcContext) {
    return null;
  }

  switch (kcContext.pageId) {
    case 'login.ftl':
      return <Login {...{ kcContext }} />;
    case 'register.ftl':
      return <Registration {...{ kcContext }} />;
    case 'info.ftl':
      return <Info {...{ kcContext }} />;
    case 'error.ftl':
      return <Error {...{ kcContext }} />;
    case 'login-reset-password.ftl':
      return <LoginResetPassword {...{ kcContext }} />;
    case 'login-verify-email.ftl':
      return <LoginVerifyEmail {...{ kcContext }} />;
    case 'login-update-profile.ftl':
      return <LoginUpdateProfile {...{ kcContext }} />;
    case 'login-idp-link-confirm.ftl':
      return <LoginIdpLinkConfirm {...{ kcContext }} />;
    case 'login-update-password.ftl':
      return <LoginUpdatePassword {...{ kcContext }} />;
    case 'update-email.ftl':
      return <UpdateEmail {...{ kcContext }} />;
    default:
      return (
        <Fallback
          doUseDefaultCss
          {...{ kcContext, i18n: {} as I18n, Template: DefaultTemplate }}
        />
      );
  }
});
