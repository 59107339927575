import { defineMessages } from 'react-intl';

export default defineMessages({
  password: {
    id: 'keycloakify.update_password.password',
    defaultMessage: 'Password',
  },
  confirm_password: {
    id: 'keycloakify.update_password.confirm_password',
    defaultMessage: 'Confirm Password',
  },
  save_password: {
    id: 'keycloakify.update_password.save_password',
    defaultMessage: 'Save password',
  },
  title: {
    id: 'keycloakify.update_password.title',
    defaultMessage: 'Enter your new Password',
  },
});
