import React from 'react';
import styles from './Button.module.scss';
import { Button } from '@ryte/ui-components';

export type ButtonProps = {
  text: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
};

const RyteButton = ({
  text,
  onClick,
  disabled,
  variant = 'secondary',
}: ButtonProps) => {
  return (
    <Button
      variant={variant}
      text={text}
      className={styles.button}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default RyteButton;
