import queryString from 'query-string';

enum SupportedLocale {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  ES = 'es',
  KO = 'ko',
}

const DEFAULT_LOCALE = SupportedLocale.EN;

// always returns valid locale
const normalizeLocale = (locale: string) => {
  switch (locale) {
    case SupportedLocale.EN:
      return SupportedLocale.EN;

    case SupportedLocale.DE:
      return SupportedLocale.DE;

    case SupportedLocale.ES:
      return SupportedLocale.ES;

    case SupportedLocale.FR:
      return SupportedLocale.FR;

    case SupportedLocale.KO:
      return SupportedLocale.KO;

    default:
      return null;
  }
};

const trimLocale = (locale: string) => {
  const trimmedLocale = locale.trim();
  return normalizeLocale(trimmedLocale.split(/-|_/)[0]) || SupportedLocale.EN;
};
/*
 *
 *  Multiple localse can be set, so we get just the first one
 *
 */
const getFirstBrowserLocale = (): SupportedLocale => {
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return DEFAULT_LOCALE;
  }
  const fistLocale = browserLocales[0];
  const trimmedLocale = trimLocale(fistLocale);

  // have to return supported locale
  return Object.values(SupportedLocale).includes(trimmedLocale)
    ? trimmedLocale
    : DEFAULT_LOCALE;
};

const getRouteLocale = (): string | null => {
  const params = queryString.parse(window.location.search);
  const lang = (params.lang && params.lang.toString()) || '';
  return normalizeLocale(lang);
  // have to return supported locale
};

export const getLocale = () => {
  // first check if locale is defined in query params, if not return browser locale
  return getRouteLocale() || getFirstBrowserLocale();
};
