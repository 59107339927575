import { useEffect, useState } from 'react';

function getSavedValue<T>(key: string, defaultValue: T): T {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    return defaultValue;
  }
}

type UseLocalStorageType = {
  value: any;
  setValue: React.Dispatch<React.SetStateAction<any>>;
};

export const useLocalStorage = <T,>(
  key: string,
  defaultValue: T
): UseLocalStorageType => {
  const [value, setValue] = useState<any>(() => {
    return getSavedValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return { value, setValue };
};
