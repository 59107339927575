import { configDevelopment, configProduction, configTesting } from './config';

enum LocationOrigin {
  Testing = 'https://login.ryte-test.com',
  Development = 'http://localhost:3000',
  Production = 'https://login.ryte.com',
}
export const getEnvironmentConfig = () => {
  switch (window.location.origin) {
    case LocationOrigin.Testing:
      return configTesting;
    case LocationOrigin.Production:
      return configProduction;
    case LocationOrigin.Development:
      return configDevelopment;
    default:
      return configDevelopment;
  }
};
