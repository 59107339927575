import React from 'react';
import cn from 'classnames';

import { Icon, IconId } from '@ryte/ui-components';
import styles from './Input.module.scss';

export type InputStyle = 'top' | 'bottom';

export type InputProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'style'
> & {
  onChange?: React.ChangeEventHandler;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  defaultValue?: string;
  type?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  style?: InputStyle;
  tabIndex?: number;
  icon: IconId;
  error?: boolean;
  className?: string;
  reference?: React.Ref<HTMLInputElement>;
};

const Input: React.FC<InputProps> = props => {
  const {
    onChange,
    placeholder,
    disabled,
    id,
    defaultValue,
    type,
    autoFocus,
    autoComplete,
    style,
    tabIndex,
    name,
    icon,
    error,
    className,
    reference,
    onBlur,
    readOnly,
  } = props;

  return (
    <span
      data-testid="InputContainer"
      className={cn(
        styles.inputContainer,
        {
          [styles.inputError]: error,
          [styles.topInput]: style === 'top',
          [styles.bottomInput]: style === 'bottom',
          [styles.disabled]: readOnly || disabled,
        },
        className
      )}>
      <Icon icon={icon} size={16} />
      <input
        data-testid="Input"
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        tabIndex={tabIndex}
        id={id}
        name={name}
        defaultValue={defaultValue}
        type={type}
        autoFocus={autoFocus}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={autoComplete}
        ref={reference}
      />
    </span>
  );
};

export default Input;
