import React from 'react';
import { IntlProvider } from 'react-intl';
import localeEN from '../../translations/en.json';
import localeDE from '../../translations/de.json';
import localeES from '../../translations/es.json';
import localeFR from '../../translations/fr.json';
import localeKO from '../../translations/ko.json';
import { ConfigProvider } from '../../configuration';
import { getLocale } from './utils';
import Footer from '../Footer';
import logo from './logo.svg';
import styles from './Wrapper.module.scss';
import { getEnvironmentConfig } from 'KcApp/configuration/utils';

const localeData: { [key: string]: any } = {
  en: localeEN,
  de: localeDE,
  es: localeES,
  fr: localeFR,
  ko: localeKO,
};

const Wrapper = ({ children }: React.PropsWithChildren<{}>) => {
  const config = getEnvironmentConfig();
  const lang = getLocale() || 'en';
  return (
    <IntlProvider locale={lang} messages={localeData[lang]}>
      <ConfigProvider>
        <div className={styles.app} data-testid="AppWrapper">
          <a
            className={styles.logo}
            href={config.ryteHomepageUrl}
            data-testid="Logo">
            <img src={logo} />
          </a>
          <div className={styles.form}>{children}</div>
          <Footer />
        </div>
      </ConfigProvider>
    </IntlProvider>
  );
};

export default Wrapper;
